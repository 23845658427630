<template>
  <el-row type="flex" justify="center" align="middle" :style="{ flex: 1 }">
    <el-col :xs="24" :sm="18">
      <el-card class="box-card register-card" v-loading="loading">
        <el-row
          type="flex"
          justify="center"
          :style="{ 'margin-bottom': '22px' }"
        >
          <el-col :xl="24" style="text-align: center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1400 1065.8"
              v-html="logo"
              class="register-logo"
            ></svg>
          </el-col>
        </el-row>
        <el-row v-if="existing">
          <p class="register-welcome">
            <strong>Hello, {{ greeting }}!</strong> An account has been created
            on your behalf by {{ $root.app.name }}. Please submit the form below
            to complete registration.
          </p>
        </el-row>
        <hr style="margin-bottom: 1rem" />
        <el-form
          @submit.native.prevent="register"
          :label-position="$root.windowWidth > 767 ? 'right' : 'top'"
          label-width="100px"
          v-if="existing"
        >
          <el-row>
            <el-col :sm="12">
              <el-form-item
                :error="
                  form.errors.first('name') ? form.errors.first('name') : null
                "
                label="Your Name"
                required
              >
                <el-input
                  v-model="existing.name"
                  type="text"
                  placeholder="Your Name"
                  @focus="form.errors.clear('name')"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12">
              <el-form-item
                :error="
                  form.errors.first('timezone')
                    ? form.errors.first('timezone')
                    : null
                "
                label="Timezone"
                required
              >
                <el-select
                  v-model="existing.timezone"
                  :style="{ width: '100%' }"
                  @focus="form.errors.clear('timezone')"
                >
                  <el-option
                    v-for="timezone in timezones"
                    :key="timezone.value"
                    :value="timezone.value"
                    :label="timezone.name"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :sm="12">
              <el-form-item
                :error="
                  form.errors.first('password')
                    ? form.errors.first('password')
                    : null
                "
                label="Password"
                required
              >
                <el-input
                  v-model="existing.password"
                  type="password"
                  @focus="form.errors.clear('password')"
                  v-input-focus
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12">
              <el-form-item label="Confirm" required>
                <el-input
                  v-model="existing.password_confirmation"
                  type="password"
                  @focus="form.errors.clear('password')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-button
            type="primary"
            plain
            medium
            native-type="submit"
            style="width: 100%; margin-top: 1rem"
          >
            Register
          </el-button>
        </el-form>
      </el-card>
    </el-col>
  </el-row>
</template>

<style lang="scss">
.register-logo {
  max-height: 150px;
  fill: rgba(212, 161, 0, 0.4);
  transition: fill 0.5s ease;
}

.register-card:hover .register-logo {
  fill: rgba(212, 161, 0, 0.9);
}

.register-welcome {
  font-size: 1.125rem;
  line-height: 1.25rem;
  text-align: center;
  margin-bottom: 1rem;
}
</style>

<script>
import Form from "form-backend-validation"

import svg from "@/theme/svg"

export default {
  name: "Register",

  mixins: [],

  data() {
    return {
      form: new Form(),
      logo: svg.comet,
      loading: false,
      existing: null,
      timezones: [
        {
          name: "Eastern",
          value: "America/New_York",
        },
        {
          name: "Central",
          value: "America/Chicago",
        },
        {
          name: "Mountain",
          value: "America/Denver",
        },
        {
          name: "Arizona",
          value: "America/Phoenix",
        },
        {
          name: "Pacific",
          value: "America/Los_Angeles",
        },
        {
          name: "Alaska",
          value: "America/Anchorage",
        },
        {
          name: "Aleutian",
          value: "America/Adak",
        },
        {
          name: "Hawaii",
          value: "Pacific/Honolulu",
        },
      ],
      greeting: "",
    }
  },

  watch: {
    $route() {
      this.routeChange()
    },
  },

  computed: {
    action() {
      if (!this.$route.query) return
      return "/register/" + this.$route.query.name
    },
  },

  methods: {
    routeChange() {
      this.loading = true

      if (
        !this.$route.query.expires ||
        !this.$route.query.signature ||
        !this.$route.query.name
      ) {
        this.$router.replace("/403?link")
      }

      this.axios
        .get(
          this.action +
            "?expires=" +
            this.$route.query.expires +
            "&signature=" +
            this.$route.query.signature
        )
        .then((response) => {
          this.existing = {
            slug: response.data.data.slug,
            name: response.data.data.name,
            timezone: "America/New_York",
            password: "",
            password_confirmation: "",
          }
          this.greeting = response.data.data.name
          this.loading = false
        })
        .catch(() => {
          this.$router.replace("/403?link")
        })
    },
    register() {
      this.axios
        .post(this.action, this.existing)
        .then(() => {
          this.$router.replace({
            name: "login",
            query: {
              registered: null,
            },
          })
          this.loading = false
        })
        .catch((resp) => {
          this.loading = false
          if (resp.response && resp.response.status === 422) {
            this.form.onFail(resp)
          } else {
            this.$root.globalRequestError(resp)
          }
        })
    },
  },

  created() {
    this.routeChange()
  },
}
</script>
